<template>
  <v-card flat :style="`height: ${ windowSize.y }px; position: 'relative'`" class="pa-1 product-view" v-resize="onResizeHandler">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card-text class="pa-0">
      <v-form ref="receiveForm" v-on:submit.prevent>
        <v-row class="ma-0">
          <v-col cols="12" class="pb-0 pt-0 font-weight-bold">{{ orderModel.number }}</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="pt-0 pr-1 pb-0 mt-1">
            <barcode-scanner v-model="scanProduct" :search="scanProduct" :scannerPayload="scannerPayload"
            :loading="productLoading"></barcode-scanner>
            <!-- <v-text-field outlined dense hide-details v-model="scanProduct" flat autocomplete="off"
              ref="scanProduct" @change="getRespectiveProductOrder" :loading="productLoading"
            :placeholder="$t('message.order.scanProduct')"></v-text-field> -->
          </v-col>
        </v-row>
        <div v-if="productFound" class="mt-1">
          <p class="mb-0">{{ $t('message.order.products') }}: {{ productInfo.productname || '' }}</p>
        </div>
          <!-- <p class="mb-0">{{ productInfo.productname || '' }}</p> -->
        <v-row>
          <!-- <v-col cols="3" class="pt-1 pb-1 pr-0 font-weight-bold" v-if="productFound">
            {{ productInfo.productname || '' }}
          </v-col> -->
          <v-col cols="6" v-if="productFound && productInfo.isbatch" class="pa-0 py-1">
            <template>
              <!-- <v-col cols="8" class="pt-0 pb-0"> -->
                <v-text-field outlined dense hide-details v-model="selectBatch" flat autocomplete="off"
                :rules="$_requiredValidation" :placeholder="$t('message.product.batch')" ref="batchRef"
                :loading="loadingBatch"></v-text-field>
              <!-- </v-col> -->
            </template>
          </v-col>
          <v-col cols="6" class="pa-0 py-1" v-if="productFound">
            <v-row class="ma-0">
              <v-col cols="3" class="text-center pa-0 mt-1" @click="decreaseQty">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-text-field outlined dense hide-details type="number" class="text-right" v-model="pickQty"
                  autocomplete="off" ref="qtyRef" :rules="$_qtyValidation" :placeholder="$t('message.product.qty')"></v-text-field>
              </v-col>
              <v-col cols="3" class="text-center pa-0 mt-1" @click="increaseQty">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="productFound" class="ma-0">
          <v-col cols="10" class="pa-1">
          </v-col>
        </v-row>
        <v-col cols="12" class="pa-0 pr-1 my-1" v-if="productFound">
          <v-autocomplete :items="listOfWarehouses" v-model="selectedWarehouse" item-text="shortname" item-value="id"
          hide-details outlined :label="$t('message.layout.warehouse')" dense :rules="$_requiredValidation"
          @change="getLocationsByWarehouse"></v-autocomplete>
        </v-col>
        <v-row v-if="productFound && locationsSet" class="mt-2">
          <v-col cols="5" class="pt-0 pb-0 px-0">
            <!-- <v-text-field outlined dense hide-details v-model="selectedLocation" flat autocomplete="off"
                :rules="$_requiredValidation" @change="checkIfLocationValid" :loading="loadingLocation"
                ref="scanLocation" :placeholder="$t('message.product.scanLoc')"></v-text-field> -->
            <v-autocomplete :items="listOfLocations" v-model="selectedLocation" item-text="name" item-value="name"
            hide-details outlined :loading="loadingLocation" :label="$t('message.product.scanLoc')" flat autocomplete="off"
            dense :rules="$_requiredValidation" ref="scanLocation" @change="checkIfLocationValid"></v-autocomplete>
          </v-col>
          <v-col cols="4" class="pt-0 pb-0 px-1">
            <v-text-field outlined dense hide-details type="text" class="text-right" v-model="qr_code"
              autocomplete="off" :label="$t('message.mixed.qrCode')"></v-text-field>
          </v-col>
        <!-- </v-row>
        <v-row v-if="productFound && locationsSet" class="mt-1"> -->
          <v-col cols="3" class="pt-0 pb-0 px-1">
            <v-text-field outlined dense hide-details type="number" class="text-right" v-model="qr_code_qty"
              autocomplete="off"  :label="$t('message.mixed.qrQty')"></v-text-field>
          </v-col>
          <v-col cols="3" class="pa-1 mt-1">
            <v-btn color="primary" dark small @click="completeOrderHandler">
              {{ $t('message.order.receive') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- LIST OF PRODUCTS -->
    <v-card-text class="highlight pt-0 pb-0 pl-1 mt-2">
      <v-row class="ma-0">
        <v-col cols="4" class="pa-0 pl-3 font-weight-bold">{{ $t('message.whsDashboard.product') }}:</v-col>
        <v-col cols="2" class="pa-0 pr-2 text-center font-weight-bold">{{ $t('message.ccsheet.unit') }}</v-col>
        <v-col cols="2" class="pa-0 text-right font-weight-bold">{{ $t('message.order.ordered') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.received') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.rest') }}</v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-divider class="pa-0"></v-divider>
        </v-col>
      </v-row>
      <!-- Load list of products -->
      <v-row v-for="(product, index) in listOfProducts" :key="index" class="ma-0" @click="scanProductByClick(product.productnumber)">
        <v-col cols="4" class="pa-0 pl-3 text-truncate" :title="product.productnumber" :class="{ 'pb-1': (index === listOfProducts.length - 1)}">
          {{ product.productnumber }}
          <br />
          <span>{{ product.productname }}</span>
        </v-col>
        <v-col cols="2" class="pa-0 text-center text-truncate" :title="product.unitname">{{ product.unitname }}</v-col>
        <v-col cols="2" class="pa-0 text-right text-truncate">{{ product.qty | absoluteNumber }}</v-col>
        <v-col cols="2" class="pa-0 pr-3 text-right text-truncate">{{ product.receivedqty_ref | absoluteNumber }}</v-col>
        <v-col cols="2" class="pa-0 pr-3 text-right text-truncate">
          {{ product.rest_qty | absoluteNumber }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn small color="primary" dark absolute bottom left tabindex="-1" @click="$router.push(`/supplier_orders/${orderId}`)">
      <v-icon left>mdi-arrow-left-bold</v-icon>{{ $t('message.login.back') }}
    </v-btn>
    <v-btn small color="primary" dark absolute bottom right tabindex="-1" @click="$router.push(`/supplier_orders/${orderId}`)">
      {{ $t('message.order.complete') }}
    </v-btn>
    <v-dialog v-model="completeDialog" persistent class="mt-0">
      <v-card>
        <v-card-title class="pa-2">
          {{ $t('message.ccsheet.info') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <h4>{{ $t('message.order.receiveWarning', { qty: ($formatter.absoluteNumber(pickQty)), product: `${productInfo.productnumber || ''} - ${this.productInfo.productname || ''}` }) }}</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" small :loading="saveLoading" @click="receiveQtyOnOrder">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="success" small @click="completeDialog = false">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Picking qty is greater than ordered -->
    <v-dialog v-model="moreQtyObj.dialog" persistent width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title> {{ $t('message.common.warning') }} </v-card-title>
        <v-card-text class="pa-5">
          <h4>{{ $t('message.order.orderedQtyHigh') }}</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="moreQtyObj.onSuccess">{{ $t('message.common.yes') }} </v-btn>
          <v-btn color="error" @click="moreQtyObj.onCancel">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  data () {
    return {
      loading: false,
      orderId: 0,
      listOfProducts: [],
      orderModel: {},
      scanProduct: '',
      windowSize: {
        x: 0,
        y: 0
      },
      productFound: false,
      productInfo: {},
      pickQty: '',
      qr_code: '',
      qr_code_qty: '',
      listOfLocations: [],
      selectedLocation: '',
      selectedLocationId: 0,
      selectBatch: '',
      saveLoading: false,
      loadingBatch: false,
      productLoading: false,
      loadingLocation: false,
      completeDialog: false,
      moreQtyObj: {
        dialog: false
      },
      listOfWarehouses: [],
      selectedWarehouse: 0,
      locationsSet: false,
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.order.scanProduct',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      }
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  mounted () {
    this.loading = true
    this.orderId = this.$route.params.order_id
    this.getOrderProductAndOthers()
    /* this.getOrderProducts()
    this.getOrderInfo() */
    this.onResizeHandler()
    this.focusField('scanProduct')
  },
  watch: {
    pickQty (val) {
      const pickedQty = parseFloat(this.$formatter.replaceCommaWithDot(val || 0))
      const products = this.$formatter.cloneVariable(this.listOfProducts)
      const getFoundProd = products.find(x => x.productid === this.productInfo.productid)
      if (getFoundProd) {
        var totalQty = 0
        var receivedCount = 0
        if (getFoundProd.receivedorders) {
          for (let i = 0; i < getFoundProd.receivedorders.length; i++) {
            if (i !== getFoundProd.receivedorders.length) {
              const receivedQty = this.$formatter.replaceCommaWithDot(getFoundProd.receivedorders[i].receivedqty)
              receivedCount += parseFloat(receivedQty)
            }
          }
        }
        totalQty += parseFloat(receivedCount)
        if (pickedQty) {
          const parentReceivedQty = pickedQty
          totalQty += parentReceivedQty
        }
        const orderedQty = this.$formatter.replaceCommaWithDot(getFoundProd.qty)
        if (parseFloat(orderedQty) < totalQty) {
          // totalQty = parseFloat(orderedQty) - parseFloat(receivedCount)
          // this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
          // setTimeout(() => {
          //   this.pickQty = totalQty
          // })
          this.moreQtyObj = {
            dialog: true,
            onSuccess: () => {
              this.moreQtyObj.dialog = false
            },
            onCancel: () => {
              totalQty = parseFloat(orderedQty) - parseFloat(receivedCount)
              setTimeout(() => {
                this.pickQty = totalQty
              })
              this.moreQtyObj.dialog = false
            }
          }
        }
      }
    }
  },
  methods: {
    /* All method hide and added method */
    getOrderProductAndOthers () { // will get all data needed for this page
      hostAppApi.get(`${this.getHostRefApi}warehouseorders/get_products_by_order/${this.orderId}?user_id=${this.userId}`)
        .then((response) => {
          if (response.data) {
            const { order, products, warehouses } = response.data
            this.orderModel = order
            this.listOfWarehouses = warehouses
            this.setProducts(products)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    /* --- */
    getOrderInfo () {
      const model = {
        filter: `id eq '${this.orderId}'`
      }
      this.$api.execute('post', 'warehouseorders/query', model)
        .then((response) => {
          if (response.data) {
            this.orderModel = response.data[0]
            this.getAllLocationsByWarehouse()
          }
        })
    },
    getAllLocationsByWarehouse () {
      const model = {
        filter: `isactive eq '1' AND warehouseid eq '${this.orderModel.warehouseid}'`
      }
      this.$api.execute('post', 'warehouselocations/query', model)
        .then((response) => {
          if (response.data) {
            this.listOfLocations = response.data
          }
        })
    },
    getOrderProducts () {
      this.loading = true
      this.$api.execute('get', `/warehouseorderitems/get_by_order_pwa/${this.orderId}`)
        .then((response) => {
          this.listOfProducts = []
          if (response.data) {
            const products = response.data
            if (products) {
              if (products) {
                for (let i = 0; i < products.length; i++) {
                  let receivedCount = 0
                  for (let j = 0; j < products[i].receivedorders.length; j++) {
                    const receivedQty = this.$formatter.replaceCommaWithDot(products[i].receivedorders[j].receivedqty)
                    receivedCount += parseFloat(receivedQty)
                  }
                  products[i].receivedqty = ''
                  products[i].receivedqty_ref = receivedCount
                  products[i].rest_qty = products[i].qty - receivedCount
                }
              }
              this.listOfProducts = products
            }
            this.restorePreset()
            this.productInfo = {}
            this.scanProduct = ''
            this.productFound = false
            this.focusField('scanProduct')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getRespectiveProductOrder () {
      this.selectedLocation = ''
      if (this.scanProduct) {
        this.productLoading = true
        const list = this.$formatter.cloneVariable(this.listOfProducts)
        if (list) {
          const getProduct = list.find(x => x.barcode === this.scanProduct || x.productnumber === this.scanProduct)
          if (getProduct) {
            this.productFound = true
            this.productInfo = getProduct
            if (this.productInfo.barcode) {
              this.qr_code = this.productInfo.barcode
              this.qr_code_qty = 1
            }
            if (getProduct.isbatch) {
              this.focusField('batchRef')
            }
          } else {
            this.productInfo = {}
            this.productFound = false
            this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.product.noProductFound' })
            this.focusField('scanProduct')
            this.scanProduct = ''
          }
          this.restorePreset()
        }
        this.productLoading = false
      }
    },
    completeOrderHandler () {
      const pickedQty = +this.$formatter.replaceCommaWithDot(this.pickQty || 0)
      setTimeout(() => {
        if (this.$refs.receiveForm.validate() && pickedQty > 0) {
          this.completeDialog = true
        } else {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
        }
      })
    },
    receiveQtyOnOrder () {
      const pickedQty = +this.$formatter.replaceCommaWithDot(this.pickQty || 0)
      if (this.$refs.receiveForm.validate() && pickedQty > 0) {
        this.saveLoading = true
        const locationid = this.selectedLocationId || 0
        const products = this.$formatter.cloneVariable(this.listOfProducts)
        const model = products.find(x => x.productid === this.productInfo.productid)
        const listOfItems = products.filter(x => x.productid !== model.productid)
        if (model) {
          if (!model.receivedorders) model.receivedorders = []
          model.locationid = locationid
          model.receivedqty = pickedQty
          let whsName = ''
          const whsObj = this.listOfWarehouses.find(x => x.id === this.selectedWarehouse)
          if (whsObj) whsName = whsObj.shortname
          const receivedOrderObj = {
            received_quantity: pickedQty,
            newly_received: true,
            batch_no: this.selectBatch || '',
            received_date: moment().format('DD.MM.YYYY'),
            qr_code_qty: (this.qr_code_qty) ? this.qr_code_qty : 1,
            qr_code: (this.qr_code) ? this.qr_code : '',
            serial_number_products: [
              {
                rec_location_id: this.selectedLocationId,
                rec_warehouse_id: this.selectedWarehouse,
                rec_warehouse_text: whsName,
                rec_location_text: this.selectedLocation,
                batch_no: this.selectBatch || ''
              }]
          }
          const itemslength = model.order_details.length - 1
          if (model.order_details && model.order_details[itemslength].order_material_id) {
            receivedOrderObj.order_material_id = model.order_details[itemslength].order_material_id
            // receivedOrderObj.product_qrcode_id = model.order_details[0].product_qrcode_id
            // receivedOrderObj.product_batch_id = model.order_details[0].product_batch_id
          }
          model.receivedorders.push(receivedOrderObj)
          /* model.receivedorders.push({
            batchnumber: this.selectBatch || '',
            receivedqty: pickedQty,
            locationid: this.selectedLocationId
          }) */
        }
        listOfItems.push(model)
        if (locationid) {
          const modelObj = { items: listOfItems }
          hostAppApi.post(`${this.getHostRefApi}warehouseorderitems/bulk_save_pwa/${this.orderId}?user_id=${this.userId}`, modelObj)
            .then((response) => {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.updatedSuccess' })
              this.completeDialog = false
              this.getOrderProductAndOthers()
            })
            .finally(() => {
              this.saveLoading = false
            })
        } else {
          this.saveLoading = false
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
        }
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
      }
    },
    onResizeHandler () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight - 40 }
    },
    decreaseQty () {
      if (this.pickQty > 0) this.pickQty--
    },
    increaseQty () {
      if (this.pickQty < 0) this.pickQty = ''
      this.pickQty++
    },
    checkIfLocationValid () {
      this.loadingLocation = true
      const locations = this.listOfLocations
      const getOnStockValue = locations.find(x => x.name === this.selectedLocation)
      if (getOnStockValue) {
        this.selectedLocationId = getOnStockValue.id
        // this.checkTheBatchnumber()
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.product.noLocFound' })
        setTimeout(() => {
          this.selectedLocation = ''
          this.selectedLocationId = 0
          this.focusField('scanLocation')
        })
      }
      this.loadingLocation = false
    },
    focusField (value) {
      requestAnimationFrame(() => {
        if (this.$refs[value]) {
          this.$refs[value].focus()
        }
      })
    },
    restorePreset () {
      this.selectBatch = ''
      this.pickQty = ''
      this.selectedLocation = ''
      this.selectedLocationId = 0
    },
    setProducts (products) {
      this.listOfProducts = []
      if (products) {
        for (let i = 0; i < products.length; i++) {
          let receivedCount = 0
          for (let j = 0; j < products[i].receivedorders.length; j++) {
            const receivedQty = this.$formatter.replaceCommaWithDot(products[i].receivedorders[j].received_quantity)
            // const receivedQty = this.$formatter.replaceCommaWithDot(products[i].receivedorders[j].receivedqty)
            receivedCount += parseFloat(receivedQty)
          }
          products[i].receivedqty = ''
          products[i].receivedqty_ref = receivedCount
          products[i].rest_qty = products[i].qty - receivedCount
        }
        this.listOfProducts = products
      }
      this.restorePreset()
      this.productInfo = {}
      this.scanProduct = ''
      this.productFound = false
      this.focusField('scanProduct')
    },
    getLocationsByWarehouse () {
      hostAppApi.get(`${this.getHostRefApi}get_location_by_warehouse/${this.selectedWarehouse}?is_supplier=1&user_id=${this.userId}&product_id=${this.productInfo.productid || 0}`)
        .then((response) => {
          if (response.data) {
            this.listOfLocations = response.data.locations || []
            this.locationsSet = true
            if (this.listOfLocations.length === 1) {
              setTimeout(() => {
                this.selectedLocation = this.listOfLocations[0].name
                this.selectedLocationId = this.listOfLocations[0].id
              }, 50)
            }
          } else this.locationsSet = false
        })
    },
    scanProductByClick (productNumber) {
      this.scanProduct = productNumber
      this.getRespectiveProductOrder()
    }
  }
}
</script>
